import {
	conferenceFailed,
	conferenceJoined,
	conferenceLeft,
	conferenceTimestampChanged,
	getMediaServerInfo,
} from '../../features/base/conference';
import { connectionDisconnect } from '../../features/base/connection';
import JitsiMeetJS, {
	JitsiConferenceErrors,
	JitsiConferenceEvents,
	JitsiConnectionEvents,
	initLib,
	isFatalJitsiConnectionError,
} from '../../features/base/lib-jitsi-meet';
import { trackAdded, trackRemoved } from '../../features/base/tracks';
import { default as connection } from './connection';
import deviceManager from './device';

import { debounce } from 'lodash';
import { getConferenceOptions } from '../../features/conference/functions';
import { JitsiOptions } from '../../features/config/jitsiConfig';
import { setMemberVoice } from '../../features/member';
import { showErrorNotification } from '../../features/notifications';
import { getLocalSharingStatus, getScreenSharingId } from '../../features/screen-share';
import Connection from './connection';

export default class jitsiServer {
	constructor() {
		this._localTracksInitialized = false;
		this.conference = null;
		this.connection = null;

		this.deviceManager = deviceManager;
		this.connection = new Connection();
	}

	getRoom() {
		return this.conference;
	}

	init() {
		APP.store.dispatch(initLib());
		const handler = {
			_localTracksInitialized: this.getLocalTracksInitialized.bind(this),
			getRoom: this.getRoom.bind(this),
		};
		this.deviceManager.init(handler);
	}

	/**
	 * Connetcion
	 */
	connect(isAddEvent = false) {
		return new Promise((resolve, reject) => {
			const connectionConfig = Object.assign({}, JitsiOptions.connectOptions);
			const media_server_info = getMediaServerInfo(APP.store.getState());
			connectionConfig.serviceUrl = `wss://${location.host}${media_server_info.url}${
				isAddEvent ? `?screen` : ''
			}`;

			this.connection.connect(connectionConfig, isAddEvent).then(jitsiCionnect => {
				function _connectionFailedHandler() {
					console.log(' 회의실 연결 실패 ');
					return;
				}

				jitsiCionnect.addEventListener(
					JitsiMeetJS.events.connection.CONNECTION_FAILED,
					_connectionFailedHandler
				);

				this.join(jitsiCionnect, isAddEvent)
					.then(conference => resolve(conference))
					.catch(err => reject(err));
			});
		});
	}

	join(jitsiConnect, isAddEvent) {
		return new Promise(async (resolve, reject) => {
			const code = APP.mateManagement.getRoom('code');

			if (!code) return reject('회의실 오류 : not code');

			const joinConfig = Object.assign({}, JitsiOptions.conferenceOptions);
			const conference = jitsiConnect.initJitsiConference(code, joinConfig);

			// 회의실 입장 성공
			function _handleConferenceJoined() {
				_unsubscribe();
				// 성공
				console.log(' jitsi conference join', isAddEvent);
				!isAddEvent && APP.store.dispatch(conferenceJoined({ conference }, 'jitsi'));

				// 회의실 이벤트 등록
				function _conferenceEventListener() {
					// 회의 입장 시간 변경
					conference.on(
						JitsiConferenceEvents.CONFERENCE_CREATED_TIMESTAMP,
						conferenceTimestamp => {
							APP.store.dispatch(conferenceTimestampChanged(conferenceTimestamp));
						}
					);

					// 회의 나가기
					conference.on(JitsiConferenceEvents.CONFERENCE_LEFT, (...args) => {
						APP.store.dispatch(conferenceTimestampChanged(0));
						APP.store.dispatch(conferenceLeft(conference, ...args));
					});

					// track 추가
					conference.on(JitsiConferenceEvents.TRACK_ADDED, track => {
						if (!track || track.isLocal()) {
							return;
						}
						const screenTrackId = getScreenSharingId(APP.store.getState);
						if (
							getLocalSharingStatus(APP.store.getState) &&
							track.ownerEndpointId === screenTrackId
						)
							return;
						APP.store.dispatch(trackAdded(track));
					});

					// track 삭제
					conference.on(JitsiConferenceEvents.TRACK_REMOVED, track => {
						if (!track || track.isLocal()) {
							return;
						}

						APP.store.dispatch(trackRemoved(track));
					});

					// set domi
					conference.on(
						JitsiConferenceEvents.DOMINANT_SPEAKER_CHANGED,
						debounce(id => {
							APP.store.dispatch(setMemberVoice(id));
						}, 1000)
					);
				}

				!isAddEvent && _conferenceEventListener();

				resolve(conference);
			}

			// 회의실 입장 실패
			function _handleConferenceFailed(err) {
				_unsubscribe();
				!isAddEvent && APP.store.dispatch(connectionDisconnect('jitsi'));
				this.conference = null;

				reject(err);
			}

			function _unsubscribe() {
				conference.off(JitsiConferenceEvents.CONFERENCE_JOINED, _handleConferenceJoined);
				conference.off(JitsiConferenceEvents.CONFERENCE_FAILED, _handleConferenceFailed);
			}

			//회의실 입장 이벤트 등록
			conference.on(JitsiConferenceEvents.CONFERENCE_JOINED, _handleConferenceJoined);
			conference.on(JitsiConferenceEvents.CONFERENCE_FAILED, _handleConferenceFailed);

			this.conference = conference;
			this.conferenceWillJoin(conference, isAddEvent).then(() => {
				conference.join();
			});
		});
	}

	async conferenceWillJoin(conference, isAddEvent) {
		if (isAddEvent) return Promise.resolve();
		const promises = [];

		const { userSelectedMicDeviceId, userSelectedCameraDeviceId } =
			APP.store.getState()['features/base/settings'];

		this.createLocalTracks({ devices: ['audio'], micDeviceId: userSelectedMicDeviceId })
			.then(async ([audio]) => {
				if (audio) {
					await this.setAudioMuteStatus(audio);

					promises.push(this.addTrack(conference, audio));
				}
			})
			.catch(err => {});

		this.createLocalTracks({ devices: ['video'], micDeviceId: userSelectedCameraDeviceId })
			.then(async ([video]) => {
				if (video) {
					await this.setVideoMuteStatus(video);
					promises.push(this.addTrack(conference, video));
				}
			})
			.catch(err => {});

		return Promise.all(promises);
	}

	addTrack(conference, t, isScreen) {
		conference
			.addTrack(t)
			.then(() => {
				// store 저장
				if (!isScreen) APP.store.dispatch(trackAdded(t));

				return Promise.resolve();
			})
			.catch(err => {
				// 트랙 생성 오류
				console.log('err', err);
				return Promise.resolve();
			});
	}

	async setVideoMuteStatus(track) {
		if (track) {
			const { userSelectedCameraMuted, request_camera } =
				APP.store.getState()['features/base/settings'];

			if (userSelectedCameraMuted || !request_camera) await track.mute();
		}
	}

	async setAudioMuteStatus(track) {
		if (track) {
			const { userSelectedAudioMuted, request_mic } =
				APP.store.getState()['features/base/settings'];

			if (userSelectedAudioMuted || !request_mic) await track.mute();
		}
	}

	disconnect() {
		const conference = this.conference;
		const state = APP.store.getState();

		if (conference && conference.isJoined()) {
			conference.leave().then(() => {
				this.connection.disconnect();
			});
		} else {
			this.connection.disconnect();
		}
	}

	// 스피커 설정
	setAudioOutputDevice(newId) {
		return this.deviceManager.setAudioOutputDevice(newId).catch(err => {
			return null;
		});
	}
	getAudioOutputDeviceId() {
		return this.deviceManager.getAudioOutputDeviceId();
	}

	createLocalTracks(options) {
		return this.deviceManager.createLocalTracksF(options);
	}
	// CREATE LOCAL TRACK 재시도
	createLocalTrackA(options) {
		return this.deviceManager.createLocalTracksA(options);
	}
	// desktop track 생성
	createDesktopTrack(options) {
		return this.deviceManager._createDesktopTrack(options);
	}

	// TRACK 음소거
	setTrackMuted(track, muted) {
		return this.deviceManager.setTrackMuted(track, muted);
	}

	/**
	 * CONFERENCE 장치 설정
	 */
	// REPLACE TRACK
	replaceConferenceTrack(oldTrack, newTrack) {
		return this.deviceManager.replaceConferenceTrack(oldTrack, newTrack);
	}
	getLocalTracksInitialized() {
		return this._localTracksInitialized;
	}

	addLocalTracksToConference(localTracks) {
		this.deviceManager.addLocalTracksToConference(localTracks);
	}
	removeLocalTracksFromConference(localTracks) {
		this.deviceManager.removeLocalTracksFromConference(localTracks, this.conference);
	}
	listenForAudioLevelUpdates(track) {
		this.deviceManager.listenForAudioUpdates(track);
	}
	removeForAudioLevelUpdates(track) {
		this.deviceManager._stopListenForAudioUpdates(track);
	}

	_getConferenceOptions() {
		return getConferenceOptions(APP.store.getState());
	}

	getConferenceFailedErr(error, room) {
		let authRequired;
		let membersOnly;
		let passwordRequired;

		switch (error.name) {
			case JitsiConferenceErrors.AUTHENTICATION_REQUIRED:
				authRequired = room;
				break;

			case JitsiConferenceErrors.CONFERENCE_ACCESS_DENIED:
			case JitsiConferenceErrors.MEMBERS_ONLY_ERROR:
				membersOnly = room;
				break;
		}

		return {
			authRequired,
			conference: undefined,
			e2eeSupported: undefined,
			error,
			joined: undefined,
			leaving: undefined,
			membersOnly,
			password: undefined,
			passwordRequired,
		};
	}

	sendActionMessage(data) {
		try {
		} catch (e) {
			console.log(e);
			// this.sendData(data.type, data.data);
		}
	}

	sendData(command, value) {
		if (!room) {
			return;
		}

		this.conference.removeCommand(command);
		this.conference.sendCommand(command, { value });
	}

	toggleVideoMuted(showUI) {
		this.deviceManager.toggleVideoMuted(showUI);
	}

	toggleAudioMuted(showUI) {
		this.deviceManager.toggleAudioMuted(showUI);
	}
}

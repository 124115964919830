import React, {lazy, Suspense, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {Loading} from './features/base/loading';
import {Modal} from './features/base/modal';
import i18next from 'i18next';
import {appMount} from './features/base/app';

require('./assets/css/reset.scss');
require('./assets/css/common.scss');

const Signup = lazy(() => import('./features/account/signup/components'));
const Login = lazy(() => import('./features/account/login/components'));
const Error = lazy(() => import('./features/options/components/error'));
const Room = lazy(() => import('./Room'));
function Mate() {
    const dispatch = useDispatch();

    useEffect(() => {
        console.log('v2.1-073');
        i18next.changeLanguage(navigator.language);
        if (APP.mateOption.name === 'edu') {
            i18next.changeLanguage('ko_edu');
        }

        window.scrollTo(0, 1);

        const meta = document.createElement('meta');
        meta.name = 'google';
        meta.content = 'notranslate';
        document.getElementsByTagName('head')[0].appendChild(meta);

        let name = 'Meetmate';
        switch (APP.mateOption.name) {
            case 'innovation':
                name = '이노베이션 아카데미-동료평가';
                break;
            case 'edu':
                name = '화상 연수';
                break;

            case 'tube':
                name = 'tubemeet';
                break;
        }
        document.title = name;

        dispatch(appMount(true));

        return () => {
            APP.UI.showLoading(false);
        };
    }, []);

    return (
        <BrowserRouter>
            <Suspense fallback={<Loading />}>
                <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/signup" component={Signup} />
                    <Route path="/error" component={Error} />

                    <Route path="/" component={Room} />
                </Switch>
                <Modal />
                <Loading />
            </Suspense>
        </BrowserRouter>
    );
}

export default Mate;

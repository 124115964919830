import React, { useEffect, useState } from 'react';
import { MicTest } from './MicTest';
import Recorder from '../recorder';

let testClass = null;
let recorder = null;

export default function AudioInputTest({ t, previewAudioTrack }) {
	const [noRecord, setNoRecord] = useState(false);
	const [prevRecord, setPrevRecord] = useState(null);
	const [file, setFile] = useState(null);
	const [isRecord, setIsRecord] = useState(false);

	useEffect(() => {
		testClass = new MicTest(setFile, setIsRecord, setPrevRecord);
		recorder = new Recorder(testClass);

		if (!recorder.isSupportedBrowser()) {
			setNoRecord(true);
			return;
		}

		return () => {
			testClass = null;
			recorder = null;
		};
	}, []);

	const onClick = () => {
		if (prevRecord) {
			prevRecord.stop();
			return;
		}

		if (!recorder.isSupportedBrowser()) {
			APP.UI.alertMessage('현재 브라우저에서는 제공되지 않습니다.');
			return;
		}

		recorder.loadAudioStraem([{ track: previewAudioTrack }]);
		// start record
		recorder.start(true);
		setPrevRecord(recorder);

		setTimeout(() => {
			recorder.stop();
		}, 4000);
	};

	if (noRecord) return;
	return (
		<>
			<div style={{ textAlign: 'center', flex: 0 }}>
				<audio
					preload="auto"
					autoPlay
					webkit-playsinline="webkit-playsinline"
					playsInline="playsInline"
					src={file}
				/>
			</div>

			{APP.function.setDevice.micTest && (
				<button className={`btn btn_middle ${isRecord ? 'btn_on' : ''}`} onClick={onClick}>
					{t(`deviceSelection.testAudio`)}
				</button>
			)}
		</>
	);
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FooterIconButton, IconButton } from '../../base/buttons';
import { getGroupId, getMainRoomRole } from '../../base/conference';
import { setGroupDual } from '../actions';
import { SHOW_GROUP_ROLE_LIST } from '../constants';
import { getGroupDualMonitor } from '../functions';
import { isWebView } from '../../base/app';

export default function SwitchGroupButton({ isOption }) {
	const mainRole = useSelector(state => getMainRoomRole(state));
	const currentGroupId = useSelector(state => getGroupId(state));

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const isDual = useSelector(state => getGroupDualMonitor(state));

	const groupName = t('options.group');
	const onClick = () => {
		if (isWebView()) {
			APP.UI.alertMessage('현재 장치에서 제공 되지 않는 기능입니다.');
			return;
		}
		dispatch(setGroupDual(!isDual));
	};

	if (!APP.layout.option.group) return null;
	else if (mainRole && !SHOW_GROUP_ROLE_LIST.includes(mainRole)) return null;
	else if (currentGroupId) return null;
	else {
		if (isOption) {
			return (
				<IconButton
					clsName={`button button_${isDual ? 'on' : 'off'}`}
					mode="group"
					name={groupName}
					size={22}
					onClick={onClick}
				/>
			);
		} else {
			return (
				<FooterIconButton
					checked={isDual}
					mode="group"
					name={groupName}
					width={43}
					height={43}
					onClick={onClick}
				/>
			);
		}
	}
}

import { getDevicePermission } from '../base/devices';
import { isCheckPermission } from '../base/localuser';
import { MEDIA_TYPE } from '../base/media';
import { PERMISSION } from '../member';
import {
	SET_RECORDING_ENABLED,
	SET_RECORD_CLASS,
	TEMP_SERVER_RECORD_START,
	TEMP_SERVER_RECORD_STOP,
} from './actionType';
import { RECORDING_TYPE } from './constants';

let serverReord;
/**
 * 서버 녹화 시작 알림. (임시)
 * @returns
 */
export function startServerRecord() {
	return (dispatch, getState) => {
		if (isCheckPermission(getState, PERMISSION.SERVER_RECORD)) {
			// 장치 권한이 존재하지 않는 경우
			const device_persmission = getDevicePermission(getState());

			if (!device_persmission[MEDIA_TYPE.AUDIO] || !device_persmission[MEDIA_TYPE.VIDEO]) {
				APP.UI.messageHandler.showError({
					descriptionKey: '카메라, 오디오의 모든 권한이 있어야 녹화가 가능합니다.',
					titleKey: '녹화 오류',
				});
				return;
			}

			dispatch(startTempServerRecord());
		}
	};
}

/**
 * 임시 서버 녹화 시작
 */
function startTempServerRecord() {
	return {
		type: TEMP_SERVER_RECORD_START,
	};
}

/**
 * 임시 서버 녹화 중지
 */
export function stopTempServerRecord() {
	return {
		type: TEMP_SERVER_RECORD_STOP,
	};
}

/**
 * 서버 녹화 시작
 * @returns
 */
export function addRecordResource() {
	return async dispatch => {
		const { complete, message } = await APP.API.addRecordResource();

		if (complete) {
			dispatch({
				type: SET_RECORDING_ENABLED,
				recordType: RECORDING_TYPE.SERVER,
				enabled: true,
				option: message,
			});
		}
	};
}

/**
 * 서버 녹화 종료
 * @returns
 */
export function stopServerRecord() {
	APP.UI.closeModal();
	return {
		type: SET_RECORDING_ENABLED,
		recordType: RECORDING_TYPE.SERVER,
		enabled: false,
		option: null,
	};
}

export function setRecordingEnabled(recordType, enabled) {
	return async (dispatch, getState) => {
		if (recordType === RECORDING_TYPE.SERVER && enabled) {
			// check API
			const { complete, message } = await APP.API.addRecordResource();

			if (complete) {
				dispatch({
					type: SET_RECORDING_ENABLED,
					recordType,
					enabled,
					option: message,
				});
			}

			return;
		}

		dispatch({
			type: SET_RECORDING_ENABLED,
			recordType,
			enabled,
			option: null,
		});
	};
}

export function setReocrdClass(recordType, recordClass) {
	return {
		type: SET_RECORD_CLASS,
		recordType,
		recordClass,
	};
}

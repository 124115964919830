// @flow

import {isAudioMuted, isVideoMutedByUser} from '../base/media';
import {CHECKING_ROOM_PASSWORD_MODAL_ID} from '../options';
import {setRoomLocked} from '../room-lock';

/**
 * Selector for determining if the device status strip is visible or not.
 *
 * @param {Object} state - The state of the app.
 * @returns {boolean}
 */
export function isDeviceStatusVisible(state) {
    return (
        !(isAudioMuted(state) && isVideoMutedByUser(state)) &&
        !state['features/base/config'].startSilent
    );
}
/**
 * Returns the text for the prejoin status bar.
 *
 * @param {Object} state - The state of the app.
 * @returns {string}
 */
export function getDeviceStatusText(state) {
    return state['features/device']?.deviceStatusText;
}

/**
 * Returns the type of the prejoin status bar: 'ok'|'warning'.
 *
 * @param {Object} state - The state of the app.
 * @returns {string}
 */
export function getDeviceStatusType(state) {
    return state['features/device']?.deviceStatusType;
}

/**
 * Selector for getting the error if any while creating streams.
 *
 * @param {Object} state - The state of the app.
 * @returns {string}
 */
export function getRawError(state) {
    return state['features/device']?.rawError;
}

export function getVdoErrorMsg(error) {
    if (!error) return 'deviceSelection.cameraOk';
    let message = 'deviceSelection.cameraError';
    const {name} = error;

    switch (name) {
        case 'NotReadableError':
            message = 'deviceSelection.cameraError';
            break;
        case 'AbortError':
            message = 'deviceSelection.cameraAbort';
            break;
        case 'NotAllowedError':
            message = 'deviceError.cameraPermission';
            break;
        // case "NotFoundError":
        // case "OverconstrainedError":
        // case "SecurityError":
        // case "TypeError":
        //     message = "deviceError.cameraError";
        //     break;
    }

    return message;
}

export async function checkRoomLocked(groupId) {
    if (groupId) {
        return {complete: true, message: true};
    }
    const {dispatch, getState} = APP.store;
    const state = getState();

    const code = APP.mateManagement.getRoom('code');
    const aToken = APP.mateManagement.getAccessToken();

    const {message, complete} = await APP.API.searchRoom({
        data: {code},
        aToken,
    });

    let isLocked = false;
    if (complete) {
        if (message.found_count > 0) {
            isLocked = message.items[0].password_checking ? true : false;
            dispatch(setRoomLocked(isLocked));
        }
    }

    if (isLocked) {
        const password = state['features/room-lock'].password;
        if (!password) {
            APP.UI.openModal(CHECKING_ROOM_PASSWORD_MODAL_ID);

            APP.UI.showLoading(false);
            return {message, complete: false};
        }
    }

    return {message, complete};
}

import { getDevicePermission } from '../base/devices';
import { MEDIA_TYPE } from '../base/media';
import { MiddlewareRegistry } from '../base/redux';
import { TRACK_ADDED, TRACK_REMOVED } from '../base/tracks';
import { getLocalIDMember, setMemberRecord } from '../member';
import { ServerTest } from './ServerTest';
import {
	SET_RECORDING_ENABLED,
	TEMP_SERVER_RECORD_START,
	TEMP_SERVER_RECORD_STOP,
} from './actionType';
import { addRecordResource, stopServerRecord } from './actions';
import { RECORDING_TYPE } from './constants';
import { getAudioTrackInRoom } from './function';
import Recorder from './recorder';
import './subscriber';

let serverClass;
MiddlewareRegistry.register(({ dispatch, getState }) => next => action => {
	switch (action.type) {
		case TEMP_SERVER_RECORD_START:
			const startRecord = () => dispatch(addRecordResource());
			const stopRecord = () => dispatch(stopServerRecord());

			const testClass = new ServerTest(startRecord, stopRecord);
			const recorder = new Recorder(testClass);

			if (!recorder.isSupportedBrowser()) {
				APP.UI.alertMessage('현재 브라우저에서는 제공되지 않습니다.');
				return;
			}

			const tracks = getAudioTrackInRoom(getState);
			recorder.loadAudioStraem(new Set(tracks.values()));
			// start record
			recorder.start(false);

			serverClass = recorder;
			break;

		case TEMP_SERVER_RECORD_STOP:
			if (serverClass) {
				serverClass.stop();
			}
			break;

		case TRACK_ADDED:
			if (action.track.track.type === MEDIA_TYPE.AUDIO) {
				if (serverClass) {
					serverClass.connectAudio(action.track.track);
				}
			}
			break;

		case TRACK_REMOVED:
			if (action.track.type === MEDIA_TYPE.AUDIO) {
				if (serverClass) {
					serverClass.disconnectAudio(action.track);
				}
			}

			break;

		case SET_RECORDING_ENABLED:
			if (action.recordType === RECORDING_TYPE.SERVER) {
				const localId = getLocalIDMember(getState);
				if (!action.enabled) serverClass = null;
				else {
					if (serverClass) {
						serverClass.useResource(action.option);
					} else break;
				}

				dispatch(setMemberRecord(localId, action.enabled));
			}
			break;
	}
	return next(action);
});

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PERMISSION } from '../../../member';
import { getDualMonitor, setDualMonitor } from '../../../video-layout';
import { IconButton } from '../../../base/buttons';
import { updateSettings } from '../../../base/settings';
import { isWebView } from '../../../base/app';

//         if (APP.mateManagement.getScreenSharing()) {
//             alert('화면 공유 중에 듀얼 모니터 사용 할 수 없습니다.');
//             return;
//         }

export default function SwitchDualMonitorButton({ permissions }) {
	if (!APP.layout.option.dualMonitor) return null;
	if (!permissions || (permissions && permissions.indexOf(PERMISSION.DUAL) === -1)) return null;

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const isDualMonitor = useSelector(state => Boolean(getDualMonitor(state)));

	const onClick = () => {
		if (isWebView()) {
			APP.UI.alertMessage('현재 장치에서 제공 되지 않는 기능입니다.');
			return;
		}
		!isDualMonitor && dispatch(updateSettings({ dualOption: true }));
		dispatch(setDualMonitor(!isDualMonitor));
		APP.UI.closeModal();
	};

	return (
		<IconButton
			mode="dual"
			name={t(`options.${isDualMonitor}DualMonitor`)}
			size={22}
			onClick={onClick}
		/>
	);
}

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '../../base/buttons';
import { translate } from '../../base/i18n';
import { isCheckPermission } from '../../base/localuser/functions';
import { PERMISSION } from '../../member';
import { stopTempServerRecord } from '../actions';
import { RECORDING_TYPE } from '../constants';
import Recorder from '../recorder';

function SwitchRecordButton({ t, type }) {
	const [noRecord, setNoRecord] = useState(false);

	if (!APP.layout.option.recorder) return null;
	else {
		if (type === RECORDING_TYPE.PERSONAL && !APP.function.record.local) return null;
		if (type === RECORDING_TYPE.SERVER && !APP.function.record.server) return null;
	}

	const permission = useSelector(state =>
		isCheckPermission(
			state,
			RECORDING_TYPE.PERSONAL === type ? PERMISSION.SELF_RECORD : PERMISSION.SERVER_RECORD
		)
	);
	if (!permission) return null;

	useEffect(() => {
		let recorder = new Recorder(null);

		if (!recorder.isSupportedBrowser()) {
			setNoRecord(true);
		}
	}, []);

	const dispatch = useDispatch();
	const isRecord = useSelector(state => state['features/recording'][type].enabled);

	const onClick = () => {
		if (noRecord) {
			APP.UI.alertMessage('현재 브라우저에서는 제공되지 않습니다.');
			return;
		}
		if (type === RECORDING_TYPE.SERVER) {
			if (!isRecord) APP.UI.openModal(type);
			else {
				dispatch(stopTempServerRecord());
			}
		}
	};

	return (
		<IconButton
			mode={type}
			name={
				type === RECORDING_TYPE.PERSONAL
					? t(`recording.${isRecord}Personal`)
					: t(`recording.${isRecord}Server`)
			}
			size={22}
			onClick={onClick}
		/>
	);
}

export default translate(SwitchRecordButton);
